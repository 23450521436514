import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, FormControlLabel, Radio, RadioGroup } from "@esgian/esgianui";
import moment from "moment";

import { api } from "../../api/api";
import { Loading } from "../../components/Loading";
import { FilterModal } from "../../components/Modal/FilterModal";
import { PowerOutputProjectList } from "../../components/PowerOutputProjectList";
import { PowerOutputProjectListWindFarm } from "../../components/PowerOutputProjectListWindFarm";
import { getErrorMessage } from "../../helper/errorHandling";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getIsLookupLoading,
} from "../../store/selector/common";
import { getProjects } from "../../store/selector/common";
import {
  getIsLoading,
  getOptions,
  getSelectedFilter,
  getTab,
} from "../../store/selector/powerOutput";
import { fetchLookup, setFilterModal } from "../../store/slice/common";
import {
  fetchList,
  setSelectedFilter,
  setTab,
} from "../../store/slice/powerOutput";
import { GenericType, MESSAGE, PowerOutputEnum } from "../../types";

export const PowerOutput = () => {
  const dispatch = useDispatch();

  const isLookupLoading = useSelector(getIsLookupLoading);
  const isFilterModalOpen = useSelector(getFilterModal);
  const options = useSelector(getOptions);
  const selectedFilter = useSelector(getSelectedFilter);
  const isLoading = useSelector(getIsLoading);
  const tab = useSelector(getTab);
  const projectsList = useSelector(getProjects);
  const [projectData, setProjectData] = useState<
    {
      name: string;
      id: number;
      dailyCapacity: number;
      dailyOutput: number;
      installedCapacity: number;
      monthlyCapacity: number;
      monthlyOutput: number;
      weeklyCapacity: number;
      weeklyOutput: number;
      source: string;
    }[]
  >([]);
  const [loadingPower, setLoadingPower] = useState(false);

  console.log(projectData);

  console.log(projectsList);

  const fetchPowerData = async (tempIds: (number | undefined)[]) => {
    setLoadingPower(true);
    try {
      const { data: dataEnergi } = await api.powerOutput.getPowerOutputEnergi({
        projectIds: tempIds,
        startTimestamp: moment().subtract(1, "year").startOf("year"),
        endTimestamp: moment(),
      });
      const { data: dataTai } = await api.powerOutput.getPowerOutputTai({
        projectIds: tempIds,
        startTimestamp: moment().subtract(1, "year").startOf("year"),
        endTimestamp: moment(),
      });
      const { data: dataEIA } = await api.powerOutput.getPowerOutputEIA({
        projectIds: tempIds,
        startTimestamp: moment().subtract(1, "year").startOf("year"),
        endTimestamp: moment(),
      });
      const { data: dataUK } = await api.powerOutput.getPowerOutputLookup();

      const allPowerData: {
        name: string;
        id: number;
        dailyCapacity: number;
        dailyOutput: number;
        installedCapacity: number;
        monthlyCapacity: number;
        monthlyOutput: number;
        weeklyCapacity: number;
        weeklyOutput: number;
        yearlyCapacity: number;
        yearlyOutput: number;
        ytdCapacity: number;
        ytdOutput: number;
        source: string;
      }[] = [];

      dataUK.windProjectStats.forEach((item: GenericType) => {
        if (
          !allPowerData.find(
            (dataPoint: GenericType) => dataPoint.id === item.id,
          )
        ) {
          allPowerData.push({
            id: item.id,
            name: item.name,
            dailyCapacity: item.dailyCapacity,
            dailyOutput: item.dailyOutput,
            installedCapacity: item.installedCapacity,
            monthlyCapacity: item.monthlyCapacity,
            monthlyOutput: item.monthlyOutput,
            weeklyCapacity: item.weeklyCapacity,
            weeklyOutput: item.weeklyOutput,
            yearlyCapacity: Math.round(item.yearlyCapacity),
            yearlyOutput: Math.round(item.yearlyOutput),
            ytdCapacity: Math.round(item.ytdCapacity),
            ytdOutput: Math.round(item.ytdOutput),
            source: "UK Grid",
          });
        }
      });

      dataEnergi.forEach((item: GenericType) => {
        if (
          !allPowerData.find(
            (dataPoint: GenericType) => dataPoint.id === item.projectId,
          )
        ) {
          allPowerData.push({
            id: item.projectId,
            name: "",
            dailyCapacity: 0,
            dailyOutput: 0,
            installedCapacity: 0,
            monthlyCapacity: 0,
            monthlyOutput: 0,
            weeklyCapacity: 0,
            weeklyOutput: 0,
            yearlyCapacity: 0,
            yearlyOutput: 0,
            ytdCapacity: 0,
            ytdOutput: 0,
            source: "Energistyrelsen Power",
          });
        }
        const foundProj = allPowerData.find(
          (data: GenericType) => data.id === item.projectId,
        );
        if (foundProj) {
          if (moment().diff(moment(item.timestamp), "days") <= 1) {
            foundProj.dailyOutput += Math.round(item.powerGeneration / 1000);
          }
          if (moment().diff(moment(item.timestamp), "week") <= 1) {
            foundProj.weeklyOutput += Math.round(item.powerGeneration / 1000);
          }
          if (moment().diff(moment(item.timestamp), "month") <= 1) {
            foundProj.monthlyOutput += Math.round(item.powerGeneration / 1000);
          }
          if (moment().diff(moment(item.timestamp), "year") <= 1) {
            foundProj.ytdOutput += Math.round(item.powerGeneration / 1000);
          }
          foundProj.yearlyOutput += Math.round(item.powerGeneration / 1000);

          foundProj.name =
            projectsList.find(
              (project) => project.windProjectId === foundProj.id,
            )?.name ?? "";
        }
      });

      dataTai.forEach((item: GenericType) => {
        if (
          !allPowerData.find(
            (dataPoint: GenericType) => dataPoint.id === item.projectId,
          )
        ) {
          allPowerData.push({
            id: item.projectId,
            name: "",
            dailyCapacity: 0,
            dailyOutput: 0,
            installedCapacity: item.installedCapacity ?? 0,
            monthlyCapacity: 0,
            monthlyOutput: 0,
            weeklyCapacity: 0,
            weeklyOutput: 0,
            yearlyCapacity: 0,
            yearlyOutput: 0,
            ytdCapacity: 0,
            ytdOutput: 0,
            source: "Tai Power",
          });
        }
        const foundProj = allPowerData.find(
          (data: GenericType) => data.id === item.projectId,
        );
        if (
          foundProj &&
          (item.utilisationPercentage !== null || item.powerGeneration !== null)
        ) {
          // let avgWeek = 0;
          // let avgMonth = 0;
          // let avgYear = 0;
          // let avgYTD = 0;
          if (moment().diff(moment(item.date), "days") <= 1) {
            foundProj.dailyOutput += Math.round(
              (item.utilisationPercentage * item.installedCapacity) / 100,
            );
            foundProj.dailyCapacity = Math.round(item.utilisationPercentage);
          }
          if (moment().diff(moment(item.date), "week") <= 1) {
            foundProj.weeklyOutput += Math.round(
              (item.utilisationPercentage * item.installedCapacity) / 100,
            );
            foundProj.weeklyCapacity += Math.round(item.utilisationPercentage);
            // avgWeek++;
          }
          if (moment().diff(moment(item.date), "month") <= 1) {
            foundProj.monthlyOutput += Math.round(
              (item.utilisationPercentage * item.installedCapacity) / 100,
            );
            foundProj.monthlyCapacity += Math.round(item.utilisationPercentage);
            // avgMonth++;
          }
          if (moment().diff(moment(item.date), "year") <= 1) {
            foundProj.ytdOutput += Math.round(
              (item.utilisationPercentage * item.installedCapacity) / 100,
            );
            foundProj.ytdCapacity += Math.round(item.utilisationPercentage);
            // avgYTD++;
          }
          foundProj.yearlyOutput += Math.round(
            (item.utilisationPercentage * item.installedCapacity) / 100,
          );
          foundProj.yearlyCapacity += Math.round(item.utilisationPercentage);
          // avgYear++;
        }
        if (foundProj) {
          foundProj.name =
            projectsList.find(
              (project) => project.windProjectId === foundProj.id,
            )?.name ?? "";
        }
      });

      dataEIA.forEach((item: GenericType) => {
        if (
          !allPowerData.find(
            (dataPoint: GenericType) => dataPoint.id === item.projectId,
          )
        ) {
          allPowerData.push({
            id: item.projectId,
            name: "",
            dailyCapacity: 0,
            dailyOutput: 0,
            installedCapacity: 0,
            monthlyCapacity: 0,
            monthlyOutput: 0,
            weeklyCapacity: 0,
            weeklyOutput: 0,
            yearlyCapacity: 0,
            yearlyOutput: 0,
            ytdCapacity: 0,
            ytdOutput: 0,
            source: "EIA",
          });
        }
        const foundProj = allPowerData.find(
          (data: GenericType) => data.id === item.projectId,
        );
        if (foundProj) {
          if (moment().diff(moment(item.timestamp), "days") <= 1) {
            foundProj.dailyOutput += Math.round(item.generation / 1000);
          }
          if (moment().diff(moment(item.timestamp), "week") <= 1) {
            foundProj.weeklyOutput += Math.round(item.generation / 1000);
          }
          if (moment().diff(moment(item.timestamp), "month") <= 1) {
            foundProj.monthlyOutput += Math.round(item.generation / 1000);
          }
          if (moment().diff(moment(item.timestamp), "year") <= 1) {
            foundProj.ytdOutput += Math.round(item.generation / 1000);
          }
          foundProj.yearlyOutput += Math.round(item.generation / 1000);

          foundProj.name =
            projectsList.find(
              (project) => project.windProjectId === foundProj.id,
            )?.name ?? "";
        }
      });
      // console.log("data here", allPowerData);
      console.log("ignore", dataEIA);
      setProjectData(
        allPowerData.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }),
      );
    } catch (err) {
      toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
    } finally {
      setLoadingPower(false);
    }
  };
  useEffect(() => {
    dispatch(fetchLookup(["projects", "company"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedView, setSelectedView] = useState("Capacity");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTab(event?.target?.value as PowerOutputEnum));
  };

  useEffect(() => {
    if (projectsList && projectsList.length > 0) {
      const tempIds = [...projectsList.map((project) => project.windProjectId)];

      if (tempIds && tempIds.length > 0) {
        fetchPowerData(tempIds);
      }
    }

    if (!isLookupLoading) {
      dispatch(fetchList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsList, tab]);

  const specificFilters: GenericType = [];
  if (options && tab && options[tab]) {
    Object.keys(options[tab]).forEach((filter) => {
      if (filter.includes(selectedView) && filter !== "installedCapacity") {
        specificFilters.push(options![tab][filter]);
      }
      if (filter === "name" || filter === "installedCapacity") {
        specificFilters.push(options![tab][filter]);
      }
    });
  }

  console.log(tab);

  return (
    <Box>
      <Box sx={{ mx: 2 }}>
        <RadioGroup
          row={true}
          aria-labelledby="view-radio-buttons"
          name="view-radio-buttons"
          value={tab}
          onChange={handleChange}
        >
          <FormControlLabel
            value={PowerOutputEnum.WindFarm}
            control={<Radio />}
            label="Wind Farm"
          />
          <FormControlLabel
            value={PowerOutputEnum.Developer}
            control={<Radio />}
            label="Developer"
          />
        </RadioGroup>
      </Box>
      {!isLookupLoading && !isLoading && !loadingPower ? (
        tab !== "wind-farm" ? (
          <PowerOutputProjectList
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            tab={tab}
          />
        ) : (
          <PowerOutputProjectListWindFarm
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            tab={tab}
            tableData={projectData}
          />
        )
      ) : (
        <Loading />
      )}
      {specificFilters.length > 0 ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => dispatch(setSelectedFilter(filterValues))}
          options={specificFilters}
          selectedFilter={selectedFilter}
        />
      ) : null}
    </Box>
  );
};
