import {
  getPowerOutput,
  getPowerOutputEnergi,
  getPowerOutputTai,
  getPowerOutputEIA,
} from "./getPowerOutput";
import { getPowerOutputLookup } from "./getPowerOutputLookup";

export const powerOutput = {
  getPowerOutputLookup,
  getPowerOutput,
  getPowerOutputEnergi,
  getPowerOutputTai,
  getPowerOutputEIA,
};
