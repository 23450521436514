import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = GenericType[];

type ResponseData = { data: GenericType[] };

export const getPowerOutput: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/PowerGeneration/ByFilters?api-version=1.0`,
  method: "POST",
  data,
});

export const getPowerOutputEnergi: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/energistyrelsenpowergeneration`,
  method: "POST",
  data,
});

export const getPowerOutputTai: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/taipowergeneration`,
  method: "POST",
  data,
});

export const getPowerOutputEIA: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/PowerGeneration/eiapowergeneration`,
  method: "POST",
  data,
});
