import React, { FC, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Box,
  FormControl,
  getEsgianTheme,
  MenuItem,
  ProgressBar,
  Select,
  Stack,
  Typography,
} from "@esgian/esgianui";
import { ApexOptions } from "apexcharts";

import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

type Prop = {
  sx?: GenericType;
  projectData?: GenericType | null;
  tab?: string | null;
};

export const PowerOutputKeyBoxDeveloper: FC<Prop> = ({
  sx,
  projectData = null,
  tab = "wind-farm",
}) => {
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  let totalValueTurbine = 0;
  let totalValueMW = 0;
  projectData?.turbineChartData.forEach((item: GenericType) => {
    totalValueTurbine += item.valueTurbine;
    totalValueMW += item.valueMW;
  });
  const chartLabels = projectData?.turbineChartData.map(
    (item: GenericType) => item.name,
  );
  const [selectedChart, setSelectedChart] = useState("turbines");

  const chartValues =
    selectedChart === "turbines"
      ? projectData?.turbineChartData.map((item: GenericType) =>
          Math.round((item.valueTurbine / totalValueTurbine) * 100),
        )
      : projectData?.turbineChartData.map((item: GenericType) =>
          Math.round((item.valueMW / totalValueMW) * 100),
        );

  let powerAvg = 0;
  projectData?.turbinePower?.split(", ").map((power: string) => {
    powerAvg += Number(power);
  });
  powerAvg = powerAvg / projectData?.turbinePower?.split(", ").length;

  const options: ApexOptions = useMemo(() => {
    return {
      chart: {
        height: 1000,
        fontFamily: [
          "Noto Sans",
          "Roboto",
          "helvetica",
          "Arial",
          "sans-serif",
        ].join(","),
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        position: "bottom",
        offsetY: -15,
        labels: {
          colors:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      stroke: {
        width: 0,
      },
      dataLabels: {
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: {
        theme: theme.mode,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#ffffff", // Change this to your desired tooltip background color
        },
        custom: function ({ series, seriesIndex, w }) {
          const backgroundColor = theme.mode === "dark" ? "#ffffff" : "#000000";
          const textColor = theme.mode === "dark" ? "#000000" : "#ffffff";
          return `<div style="background-color: ${backgroundColor}; padding: 10px; border-radius: 5px;">
                    <span style="color: ${textColor};">${w.globals.labels[seriesIndex]}: ${series[seriesIndex]}</span>
                  </div>`;
        },
      },
      title: {
        align: "left",
        style: {
          fontSize: "20px",
          color:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
      labels: chartLabels ?? [],
    };
  }, [theme, themeMode, chartLabels]);

  return (
    <Box
      sx={{
        background:
          themeMode === ThemeModeEnum.Dark ? "#171717" : "rgb(217, 217, 217)",
        p: 2,
        height: "22rem",
        borderRadius: 4,
        color:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
        ...sx,
      }}
    >
      <Stack direction="column" spacing={2}>
        {tab === "wind-farm" && (
          <Typography variant="h6">Key figures</Typography>
        )}
        <Stack direction="column" spacing={1}>
          <Typography variant="body2">Power data coverage</Typography>
          <ProgressBar value="10" />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxHeight: "16vh",
            mt: 10,
            justifyContent: "space-around",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            spacing={10}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              textAlign: "center",
              justifyContent: "space-around",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{
                maxHeight: "16vh",
                mt: 10,
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Installed capacity</Typography>
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  {projectData?.installedCapacity} MW
                </Typography>
              </Stack>
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Power generation</Typography>
                <Typography variant="h6">TBI</Typography>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                height: "16vh",
                maxHeight: "16vh",
                mt: 10,
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Average turbine size</Typography>
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  {Math.round(powerAvg)} MW
                </Typography>
              </Stack>
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Capacity factor</Typography>
                <Typography variant="h6">TBI</Typography>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                height: "16vh",
                maxHeight: "16vh",
                mt: 10,
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Number of turbines</Typography>
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  {projectData?.numberOfTurbines}
                </Typography>
              </Stack>
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Max. capacity factor</Typography>
                <Typography variant="h6">TBI</Typography>
              </Stack>
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              sx={{
                height: "16vh",
                maxHeight: "16vh",
                mt: 10,
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Project coverage</Typography>
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  TBI
                </Typography>
              </Stack>
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Min. capacity factor</Typography>
                <Typography variant="h6">TBI</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row">
            <FormControl disabled={false} size="small">
              <Select
                variant="outlined"
                inputProps={{ sx: { pl: 1, pr: 1, pt: 0.5, pb: 0.5 } }}
                value={selectedChart}
                onChange={({ target }: GenericType) =>
                  setSelectedChart(target.value)
                }
                autoWidth="true"
              >
                <MenuItem value="turbines">No. of turbines</MenuItem>
                <MenuItem value="mw">MW installed</MenuItem>
              </Select>
            </FormControl>
            <ReactApexChart
              options={options}
              series={chartValues ?? []}
              type="pie"
              height={250}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
