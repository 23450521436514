/* eslint-disable indent */
import { toast } from "react-toastify";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { CancelTokenSource } from "axios";
import moment, { Moment } from "moment";

import { api } from "../../api/api";
import { getErrorMessage } from "../../helper/errorHandling";
import {
  aggregateMinMaxValues,
  generateDateFilter,
  generateOptions,
  mapFilterData,
  transformNumericAttributesForFilter,
} from "../../helper/fllter";
import {
  CountryInfo,
  FeatureLegendsTypeEnum,
  GenericType,
  IdNamePair,
  LeaseAreaData,
  LeaseInfo,
  LeaseRoundInfo,
  Lookup,
  MapFeature,
  MESSAGE,
  ProjectInfo,
  RootState,
  SelectedFilterIds,
  SortingInfo,
  WindLeasingAndProjectTabEnum,
} from "../../types";

let cancelTokenSource: CancelTokenSource;
const foundationIds = [5, 13, 14, 15, 16, 17, 18, 19, 20, 21, 48, 49];
const cableIds = [3, 31, 32, 42];

type State = {
  isLoading: boolean;
  polygons: MapFeature[] | null;
  isInfoModalOpen: boolean;
  leaseRegionLookup: Lookup[] | null;
  blurred: boolean;
  countryListInfo: CountryInfo[] | null;
  leaseListInfo: LeaseInfo[] | null;
  projectListInfo: ProjectInfo[] | null;
  leaseRoundListInfo: LeaseRoundInfo[] | null;
  listViewSelectedTab: WindLeasingAndProjectTabEnum;
  selectedFilter: GenericType;
  projectFilterOptions: GenericType | null;
  projectSelectedFilter: GenericType;
  mapActiveLegends: (string | number)[];
  sortingInfo: SortingInfo;
  visibleColumns: string[];
};

const initialState: State = {
  isLoading: false,
  polygons: null,
  isInfoModalOpen: false,
  leaseRegionLookup: null,
  blurred: false,
  countryListInfo: null,
  leaseListInfo: null,
  projectListInfo: null,
  leaseRoundListInfo: null,
  listViewSelectedTab: WindLeasingAndProjectTabEnum.Project,
  selectedFilter: {},
  projectFilterOptions: null,
  projectSelectedFilter: {},
  mapActiveLegends: Object.values(FeatureLegendsTypeEnum)?.filter(
    (value) =>
      (typeof value === "string" || typeof value === "number") &&
      value !== "Suspended",
  ),
  sortingInfo: {
    direction: "asc",
    name: "project",
  },
  visibleColumns: [
    "project",
    "country",
    "developers",
    "projectStatus",
    "projectType",
    "capacity",
    "numberOfTurbines",
    "operationDate",
    "decommissioningDate",
    "foundationInstallationStartDate",
    "turbineInstallationStartDate",
    "leaseRound",
    "leaseAreas",
    "seaRegion",
    "maximumWaterDepth",
    "foundationInstaller",
  ],
};

export const fetchPolygons = createAsyncThunk(
  "map/polygon/coordinates",
  async (polygon: string, { getState }) => {
    try {
      toast.info(`${MESSAGE.InfoPolygonFetch}`);
      const state = getState() as RootState;
      const projectStatus = state.common.projectStatus;
      const selectedFilter = mapFilterData(
        state.windLeasingAndProject.selectedFilter,
      );
      const regionTypeIds = [];
      if (
        state.windLeasingAndProject.mapActiveLegends.includes(
          FeatureLegendsTypeEnum.LeaseArea,
        )
      ) {
        regionTypeIds.push(3);
      }
      const projectStatusIds = state.windLeasingAndProject.mapActiveLegends
        ?.filter(
          (i) =>
            i !== FeatureLegendsTypeEnum.LeaseArea &&
            i !== FeatureLegendsTypeEnum.ProjectUndefined,
        )
        .map((i) => projectStatus.find((s) => s.status === i)?.id)
        ?.filter(
          (id) =>
            id !== null &&
            (!selectedFilter?.projectStatusIds?.length ||
              selectedFilter?.projectStatusIds.includes(id)),
        );

      if (projectStatusIds.length > 0) {
        regionTypeIds.push(4);
      }

      cancelTokenSource?.cancel("Operation canceled due to new request.");
      cancelTokenSource = axios.CancelToken.source();
      if (regionTypeIds.length) {
        const { data } = await api.polygon.getPolygon({
          input: {
            ...selectedFilter,
            polygon,
            regionTypeIds,
            projectStatusIds,
          },
          cancelToken: cancelTokenSource,
        });
        toast.success(`${MESSAGE.SuccessPolygonFetch}`);
        return data;
      }
      return [];
    } catch (err) {
      return [];
    }
  },
);

export const fetchRegionCoordinates = createAsyncThunk(
  "map/region/coordinates",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const projectStatus = state.common.projectStatus;
      const selectedFilter = mapFilterData(
        state.windLeasingAndProject.selectedFilter,
      );
      const regionTypeIds = [];
      if (
        state.windLeasingAndProject.mapActiveLegends.includes(
          FeatureLegendsTypeEnum.LeaseArea,
        )
      ) {
        regionTypeIds.push(3);
      }
      const projectStatusIds = state.windLeasingAndProject.mapActiveLegends
        ?.filter(
          (i) =>
            i !== FeatureLegendsTypeEnum.LeaseArea &&
            i !== FeatureLegendsTypeEnum.ProjectUndefined,
        )
        .map((i) => projectStatus.find((s) => s.status === i)?.id)
        ?.filter(
          (id) =>
            id !== null &&
            (!selectedFilter?.projectStatusIds?.length ||
              selectedFilter?.projectStatusIds.includes(id)),
        );
      if (projectStatusIds.length > 0) {
        regionTypeIds.push(4);
      }
      if (regionTypeIds.length) {
        const { data } = await api.wind.getRegion({
          ...selectedFilter,
          regionTypeIds: regionTypeIds,
          projectStatusIds: projectStatusIds || undefined,
        });
        toast.success(`${MESSAGE.SuccessRegionFetch}`);
        return data;
      }
      return [];
    } catch (err) {
      toast.error(`${MESSAGE.ErrorRegionFetch}`);
    }
  },
);

export const fetchCountryInfo = createAsyncThunk(
  "fetchCountryInfo",
  async (filter: SelectedFilterIds) => {
    try {
      const { data } = await api.country.getCountryListInfo(filter);
      return data;
    } catch (err) {
      toast.error(`${MESSAGE.Country}: ${getErrorMessage(err)}`);
    }
  },
);

export const fetchLeaseRegionInfo = createAsyncThunk(
  "getRegionListInfo",
  async (filter: SelectedFilterIds, { getState }) => {
    try {
      const { common } = getState() as RootState;
      const { data } = await api.lease.getRegionListInfo(filter);
      const res = data
        ?.filter((l: LeaseAreaData) => l?.leaseRegion?.length)
        ?.map((item: LeaseAreaData) => {
          return {
            ...item,
            id: item?.leaseRegion?.[0]?.id,
            name: item?.leaseRegion?.[0]?.name,
            country: common.countries?.find(
              (c: { id: number }) => c.id === item.leaseRoundInfo?.countryId,
            )?.name,
            seaRegion: item?.seaRegion?.[0]?.name,
            leaseRoundName: item?.leaseRoundInfo?.leaseRound?.name,
            leaseRoundType: item?.leaseRoundInfo?.leaseRoundType?.type,
            leaseRoundStatus: item?.leaseRoundInfo?.leaseRoundStatus?.status,
          };
        });
      return res;
    } catch (err) {
      console.log(">>>", err);
      toast.error(`${MESSAGE.LeaseArea}: ${getErrorMessage(err)}`);
    }
  },
);

export const fetchProjectInfo = createAsyncThunk(
  "fetchProjectInfo",
  async (_, { getState }) => {
    try {
      const { common } = getState() as RootState;
      const { data } = await api.project.getProjectListInfo({});
      const tableData = data.map((item: GenericType) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { equipmentContracts, vesselContracts, windProjectContracts } =
          // eslint-disable-next-line no-unsafe-optional-chaining
          item?.contracts;
        const turbineSupplier = equipmentContracts?.turbineContracts
          ?.filter(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) =>
              contract?.contractWorkType?.type === "Manufacturing",
          )
          .map(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) =>
              contract?.contractedCompanies?.map(
                (c: GenericType) => c?.contractee?.name,
              ),
          )
          ?.flat();
        const foundationSupplier = equipmentContracts?.foundationContracts
          ?.filter(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) =>
              contract?.contractWorkType?.type === "Manufacturing",
          )
          .map(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) =>
              contract?.contractedCompanies?.map(
                (c: GenericType) => c?.contractee?.name,
              ),
          )
          ?.flat();
        const foundationContracts =
          equipmentContracts?.foundationContracts ?? [];
        const turbineContracts = equipmentContracts?.turbineContracts ?? [];
        const port = [...foundationContracts, ...turbineContracts]
          ?.filter(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) =>
              contract?.contractWorkType?.type === "Installation",
          )
          .flatMap(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) =>
              contract?.ports?.map((p: GenericType) => p.portName) || [],
          )
          ?.filter((c: string) => c)
          .reduce((acc, portName) => {
            if (!acc.includes(portName)) {
              acc.push(portName);
            }
            return acc;
          }, [] as string[]);
        const turbineInstaller = vesselContracts
          ?.map((c: GenericType) =>
            c.turbineContracts.map((tc: GenericType) => ({
              ...tc,
              vesselName: c.vessel.vesselName,
            })),
          )
          .flat()
          ?.filter(
            (c: GenericType) =>
              c.workType?.type === "Installation" ||
              (c.contractStatus?.status === "Speculative" && c?.vesselName),
          )
          .map((c: GenericType) =>
            c.contractedParties.map((p: GenericType) => p?.contractee?.name),
          )
          .flat();

        const cableInstaller = vesselContracts
          ?.map((c: GenericType) =>
            c.turbineContracts.map((tc: GenericType) => ({
              ...tc,
              vesselName: c.vessel.vesselName,
            })),
          )
          .flat()
          ?.filter(
            (c: GenericType) =>
              cableIds.includes(c?.equipmentTypes?.[0]?.id) &&
              (c.workType?.type === "Installation" ||
                (c.contractStatus?.status === "Speculative" && c?.vesselName)),
          )
          .map((c: GenericType) =>
            c.contractedParties.map((p: GenericType) => p?.contractee?.name),
          )
          .flat();

        const foundationInstaller = vesselContracts
          ?.map((c: GenericType) =>
            c.turbineContracts.map((tc: GenericType) => ({
              ...tc,
              vesselName: c.vessel.vesselName,
            })),
          )
          .flat()
          ?.filter(
            (c: GenericType) =>
              foundationIds.includes(c?.equipmentTypes?.[0]?.id) &&
              (c?.workType?.type === "Installation" ||
                (c.contractStatus?.status === "Speculative" && c?.vesselName)),
          )
          .map((c: GenericType) =>
            c.contractedParties.map((p: GenericType) => p?.contractee?.name),
          )
          ?.flat();
        const foundationMerged = [
          ...(vesselContracts?.flatMap(
            (c: GenericType) => c.turbineContracts,
          ) || []),
          ...(windProjectContracts?.length
            ? windProjectContracts.filter((wc: GenericType) => wc.startDate)
            : []),
        ]?.filter(
          (c: GenericType) =>
            (foundationIds.includes(c?.equipmentTypes?.[0]?.id) &&
              c?.workType?.type === "Installation") ||
            (foundationIds.includes(c?.equipmentTypeIds?.[0]) &&
              c?.contractWorkType?.type === "Installation"),
        );

        const foundationInstallationStartDate =
          foundationMerged
            ?.map((contract: GenericType) => moment(contract.startDate))
            ?.reduce((earliest: Moment | undefined, current: Moment) => {
              if (!earliest || current.isBefore(earliest)) {
                return current;
              }
              return earliest;
            }, undefined)
            ?.format("YYYY-MM-DD") || null;

        const turbineMerged = [
          ...(vesselContracts
            ?.flatMap((c: GenericType) => c.turbineContracts)
            ?.filter(
              (c: GenericType) => c?.equipmentTypes?.[0]?.type === "Turbine",
            ) || []),
          ...(windProjectContracts?.length
            ? windProjectContracts.filter(
                (wc: GenericType) =>
                  wc.startDate && wc.equipmentTypeIds.includes(11),
              )
            : []),
        ]?.filter(
          (c: GenericType) =>
            c?.workType?.type === "Installation" ||
            c?.contractWorkType?.type === "Installation",
        );

        const turbineInstallationStartDate =
          turbineMerged
            ?.map((contract: GenericType) => moment(contract.startDate))
            ?.reduce((earliest: Moment | undefined, current: Moment) => {
              if (!earliest || current.isBefore(earliest)) {
                return current;
              }
              return earliest;
            }, undefined)
            ?.format("YYYY-MM-DD") || null;

        return {
          id: item?.windProject?.id,
          fid: item?.windProject?.fid
            ? moment(item?.windProject?.fid).format("YYYY-MM-DD")
            : "",
          ppaDetails: item.contracts?.windProjectContracts?.some(
            (contract: GenericType) => {
              return contract.ppaDetails;
            },
          )
            ? "Yes"
            : "No",
          project: item?.windProject?.name,
          developers: item?.developers?.map((d: GenericType) => d.companyName),
          projectStatus: item?.windProject?.projectStatus?.status,
          projectType: item?.windProject?.projectType?.type,
          capacity: item?.windProject?.capacityMw,
          seaRegion: item?.seaRegion?.map((s: GenericType) => s.name).join(","),
          leaseRound: item?.windProject?.leaseRound?.name,
          leaseAreas: item?.leaseRegion
            ?.map((l: GenericType) => l.name)
            .join(","),
          country: common.countries?.find(
            (c) => c.id === item?.windProject?.countryId,
          )?.name,
          operationDate: item?.windProject?.operationDate
            ? moment(item?.windProject?.operationDate).format("YYYY-MM-DD")
            : null,
          decommissioningDate: item?.windProject?.decommissioningDate
            ? moment(item?.windProject?.decommissioningDate).format(
                "YYYY-MM-DD",
              )
            : null,
          numberOfTurbines: item?.windProject?.numTurbines,
          maximumWaterDepth: item?.projectRegion?.[0]?.maxDepthM,
          intertidal: item?.windProject?.fixedProjectDetails?.[0]?.intertidal
            ? "Yes"
            : "No",
          freshWaterLocation: item?.windProject?.freshWaterLocation
            ? "Yes"
            : "No",
          turbineSupplier,
          turbineModel: item?.windProject?.turbineModels
            ?.map((t: IdNamePair) => t.name)
            .join(", "),
          hubHeight: item?.windProject?.hubHeightM,
          rotorDiameter: item?.windProject?.turbineModels
            ?.map((t: GenericType) => t.rotorDiameterM)
            .join(", "),
          floatingMaterial:
            item?.windProject?.floatingProjectDetails?.[0]
              ?.floatingFoundationTechnology?.floaterMaterial?.material,
          floaterType: item?.windProject?.floatingProjectDetails?.map(
            (f: GenericType) =>
              f?.floatingFoundationTechnology?.floaterType?.type,
          ),
          anchorMaterial:
            item?.windProject?.floatingProjectDetails?.[0]?.anchorMaterial
              ?.material,
          totalNumberAnchors:
            item?.windProject?.floatingProjectDetails?.[0]?.numAnchors,
          totalNumberMooringLines:
            item?.windProject?.floatingProjectDetails?.[0]?.numMooringLines,
          mooringLineLength:
            item?.windProject?.floatingProjectDetails?.[0]?.mooringLineLengthM,
          displacementPerUnit:
            item?.windProject?.floatingProjectDetails?.[0]?.displacementCbm,
          draught: item?.windProject?.floatingProjectDetails?.[0]?.draughtM,
          hullMassPerUnit:
            item?.windProject?.floatingProjectDetails?.[0]?.hullMassMt,
          ballastMassPerUnit:
            item?.windProject?.floatingProjectDetails?.[0]?.ballastMassMt,
          storageMethod:
            item?.windProject?.hydrogenProjectDetail?.hydrogenTechnology
              ?.hydrogenStorageMethod?.method,
          oilGasFields: item?.windProject?.oilGasProjectDetail?.oilGasFieldName,
          endLife:
            item?.windProject?.oilGasProjectDetail?.oilGasFieldName
              ?.endOfLifeDate,
          oilGasFieldOwnership: item?.windProject?.oilGasProjectDetail?.owners
            ?.map((o: GenericType) => o.companyName)
            .join(", "),
          turbineNominalPower: item?.windProject?.turbineModels
            ?.map((t: GenericType) => t.ratedPowerMw)
            ?.filter((v: number) => v)
            ?.sort((a: string, b: string) => parseFloat(a) - parseFloat(b))
            .join(", "),
          foundationSupplier,
          foundationType:
            item?.windProject?.fixedProjectDetails?.[0]?.fixedFoundationType
              ?.type,
          mainPortName: port,
          turbineInstaller,
          cableInstaller,
          foundationInstaller,
          foundationInstallationStartDate:
            foundationInstallationStartDate ?? null,
          turbineInstallationStartDate: turbineInstallationStartDate ?? null,
          floatingTechnology:
            item?.windProject?.floatingProjectDetails?.[0]
              ?.floatingFoundationTechnology?.name,
          mooringLineType:
            item?.windProject?.floatingProjectDetails?.[0]?.mooringType?.type,
          turbinesPerFloatingUnit:
            item?.windProject?.floatingProjectDetails?.[0]
              ?.floatingFoundationTechnology?.turbinesPerFoundation,
          hydrogenProject: item?.windProject?.hydrogenProjectDetail?.name,
          hydrogenDeveloper:
            item?.windProject?.hydrogenProjectDetail?.developer?.companyName,
          hydrogenTechnology:
            item?.windProject?.hydrogenProjectDetail?.hydrogenTechnology?.name,
          dateOnline: item?.windProject?.hydrogenProjectDetail?.operationDate
            ? moment(
                item?.windProject?.hydrogenProjectDetail?.operationDate,
              ).format("YYYY-MM-DD")
            : null,
          electrolysisMethod:
            item?.windProject?.hydrogenProjectDetail?.hydrogenTechnology
              ?.electrolysisTechnology?.technology,
          electrolyserSupplier:
            item?.windProject?.hydrogenProjectDetail?.supplier?.companyName,
          tipHeight: item?.windProject?.tipHeight,
          distributionMethod:
            item?.windProject?.hydrogenProjectDetail?.hydrogenTechnology
              ?.hydrogenDistributionMethod?.method,
          distanceShore:
            item?.projectRegion?.[0]?.maxDistFromShoreNm ||
            item?.projectRegion?.[0]?.minDistFromShoreNm,
          area: item?.projectRegion?.[0]?.areaSqkm?.toFixed(0),
          hydrogen: item?.windProject?.hydrogenProjectDetail ? "Yes" : "No",
          oilAndGas: item?.windProject?.oilGasProjectDetail ? "Yes" : "No",
        };
      });

      const groups: GenericType = {
        keyInfo: [
          "project",
          "country",
          "developers",
          "projectStatus",
          "projectType",
          "capacity",
          "numberOfTurbines",
          "operationDate",
          "decommissioningDate",
          "leaseRound",
          "seaRegion",
          "maximumWaterDepth",
        ],
        equipment: [
          "turbineSupplier",
          "turbineModel",
          "foundationSupplier",
          "foundationType",
        ],
        vessels: [
          "mainPortName",
          "turbineInstaller",
          "cableInstaller",
          "foundationInstaller",
        ],
        floating: [
          "floatingMaterial",
          "floatingTechnology",
          "floaterType",
          "anchorMaterial",
          "mooringLineType",
        ],
        hydrogen: [
          "hydrogen",
          "hydrogenProject",
          "hydrogenDeveloper",
          "hydrogenTechnology",
          "dateOnline",
          "electrolysisMethod",
          "electrolyserSupplier",
          "storageMethod",
          "distributionMethod",
        ],
        oilAndGas: ["oilAndGas", "oilAndGasFields", "oilAndGasFieldOwnership"],
      };
      const numericAttributesList = [
        "capacity",
        "numberOfTurbines",
        "maximumWaterDepth",
        "distanceShore",
        "area",
        "turbineNominalPower",
        "tipHeight",
        "hubHeight",
        "rotorDiameter",
        "totalNumberAnchors",
        "totalNumberMooringLines",
        "mooringLineLength",
        "displacementPerUnit",
        "turbinesPerFloatingUnit",
        "draught",
        "hullMassPerUnit",
        "ballastMassPerUnit",
      ];
      const multiSelectAttributesList = [
        "project",
        "developers",
        "projectStatus",
        "projectType",
        "seaRegion",
        "leaseRound",
        "country",
        "intertidal",
        "freshWaterLocation",
        "turbineSupplier",
        "turbineModel",
        "foundationSupplier",
        "foundationType",
        "mainPortName",
        "turbineInstaller",
        "cableInstaller",
        "foundationInstaller",
        "floatingMaterial",
        "floatingTechnology",
        "floaterType",
        "anchorMaterial",
        "mooringLineType",
        "hydrogenProject",
        "hydrogenDeveloper",
        "hydrogenTechnology",
        "electrolysisMethod",
        "electrolyserSupplier",
        "storageMethod",
        "distributionMethod",
        "oilAndGas",
        "oilAndGasFields",
        "endLife",
        "oilAndGasFieldOwnership",
        "hydrogen",
      ];
      const dateAttributesList = [
        "operationDate",
        "decommissioningDate",
        "foundationInstallationStartDate",
        "turbineInstallationStartDate",
        "dateOnline",
      ];
      const filterOptions: GenericType = {
        keyInfo: {},
        equipment: {},
        vessels: {},
        floating: {},
        hydrogen: {},
        oilAndGas: {},
      };
      let selectedAttributes: GenericType = {};
      Object.keys(groups).forEach((group) => {
        groups[group].forEach((attribute: string) => {
          if (numericAttributesList.includes(attribute)) {
            const currentAttribute = aggregateMinMaxValues(tableData, [
              attribute,
            ]);
            selectedAttributes = {
              ...selectedAttributes,
              ...currentAttribute,
            };
            filterOptions[group] = {
              ...filterOptions[group],
              ...transformNumericAttributesForFilter(currentAttribute),
            };
          } else if (multiSelectAttributesList.includes(attribute)) {
            filterOptions[group][attribute] = generateOptions(tableData, [
              attribute,
            ])[attribute];
          } else if (dateAttributesList.includes(attribute)) {
            filterOptions[group][attribute] = generateDateFilter(attribute);
          }
        });
      });
      return {
        data: tableData.map((p: GenericType) => ({
          ...p,
          foundationSupplier: p?.foundationSupplier?.join(", "),
          developers: p?.developers?.join(", "),
          turbineSupplier: p?.turbineSupplier?.join(", "),
          turbineInstaller: p?.turbineInstaller?.join(", "),
          cableInstaller: p?.cableInstaller?.join(", "),
          foundationInstaller: p?.foundationInstaller?.join(", "),
          floaterType: p?.floaterType?.join(", "),
          mainPortName: p?.mainPortName?.join(", "),
        })),
        filterOptions,
        selectedFilter: {},
      };
    } catch (err) {
      console.log(">>>", err);
      toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
      return null;
    }
  },
);

export const fetchLeaseRoundInfo = createAsyncThunk(
  "fetchLeaseRoundInfo",
  async (filter: SelectedFilterIds, { getState }) => {
    try {
      const { common } = getState() as RootState;
      const { data } = await api.lease.getRoundListInfo(filter);
      const res = data.map((item: LeaseRoundInfo) => {
        const leasArea = item?.leaseAreas?.map((l) => l.name);
        return {
          id: item?.leaseRoundInfo?.leaseRound?.id,
          name: item?.leaseRoundInfo?.leaseRound?.name,
          leaseRoundType: item?.leaseRoundInfo?.leaseRoundType?.type,
          leaseRoundStatus: item?.leaseRoundInfo?.leaseRoundStatus?.status,
          country: common.countries?.find(
            (c) => c.id === item?.leaseRoundInfo?.countryId,
          )?.name,
          seaRegion: item?.seaRegions
            ?.map((reg: IdNamePair) => reg.name)
            .join(", "),
          leaseAreas: leasArea?.length
            ? leasArea.join(", ")
            : item?.leaseRoundInfo?.leaseRound?.name,
          targetCapacityMW: item?.leaseRoundInfo?.targetCapacityMW,
          projectsConfirmed: item?.projectsConfirmed,
          bidsNumber: item?.bids > 0 ? item?.bids : null,
        };
      });
      return res;
    } catch (err) {
      console.log(">>>", err);
      toast.error(`${MESSAGE.LeaseRound}: ${getErrorMessage(err)}`);
    }
  },
);

const windLeasingAndProjectSlice = createSlice({
  name: "windLeasingAndProject",
  initialState,
  reducers: {
    setInfoModal: (state, action: PayloadAction<boolean>) => {
      state.isInfoModalOpen = action.payload;
    },
    setBlurred: (state, action: PayloadAction<boolean>) => {
      state.blurred = action.payload;
    },
    setListViewSelectedTab: (
      state,
      action: PayloadAction<WindLeasingAndProjectTabEnum>,
    ) => {
      state.listViewSelectedTab = action.payload;
    },
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      let filter = action.payload;
      if (filter.timeline) {
        filter = {
          ...filter,
          startDate: filter.timeline[0],
          endDate: filter.timeline[1],
        };
      }
      state.selectedFilter = action.payload;
    },
    setProjectSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.projectSelectedFilter = action.payload;
    },
    setMapActiveLegends: (state, action: PayloadAction<string | number>) => {
      const index = state.mapActiveLegends.indexOf(action.payload);

      if (index >= 0) {
        state.mapActiveLegends.splice(index, 1);
      } else {
        state.mapActiveLegends.push(action.payload);
      }
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
    setVisibleColumns: (state, action: PayloadAction<string | string[]>) => {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.visibleColumns = payload;
      } else if (state.visibleColumns.includes(payload)) {
        state.visibleColumns = state.visibleColumns.filter(
          (col) => col !== payload,
        );
      } else {
        state.visibleColumns.push(payload);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPolygons.pending, function (state) {
        state.isLoading = true;
        state.blurred = true;
      })
      .addCase(fetchPolygons.rejected, (state) => {
        state.polygons = null;
        state.blurred = false;
      })
      .addCase(fetchPolygons.fulfilled, (state, action) => {
        state.blurred = false;
        state.polygons = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(fetchRegionCoordinates.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchRegionCoordinates.rejected, () => {
        toast.error(`${MESSAGE.UnexpectedError}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .addCase(fetchRegionCoordinates.fulfilled, (state, action) => {
        state.leaseRegionLookup = action.payload || [];
        state.isLoading = false;
      });
    builder
      .addCase(fetchCountryInfo.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchCountryInfo.rejected, () => {
        toast.error(`${MESSAGE.UnexpectedError}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .addCase(fetchCountryInfo.fulfilled, (state, action) => {
        state.countryListInfo = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(fetchLeaseRegionInfo.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchLeaseRegionInfo.rejected, () => {
        toast.error(`${MESSAGE.UnexpectedError}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .addCase(fetchLeaseRegionInfo.fulfilled, (state, action) => {
        state.leaseListInfo = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(fetchProjectInfo.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchProjectInfo.rejected, () => {
        toast.error(`${MESSAGE.UnexpectedError}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .addCase(fetchProjectInfo.fulfilled, (state, action) => {
        if (action?.payload) {
          state.projectListInfo = action.payload.data as ProjectInfo[];
          state.projectFilterOptions = action.payload?.filterOptions;
          state.projectSelectedFilter = action.payload.selectedFilter;
          state.isLoading = false;
        }
      });
    builder
      .addCase(fetchLeaseRoundInfo.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchLeaseRoundInfo.rejected, () => {
        toast.error(`${MESSAGE.UnexpectedError}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .addCase(fetchLeaseRoundInfo.fulfilled, (state, action) => {
        state.leaseRoundListInfo = action.payload;
        state.isLoading = false;
      });
  },
});

export const {
  setInfoModal,
  setBlurred,
  setListViewSelectedTab,
  setSelectedFilter,
  setProjectSelectedFilter,
  setMapActiveLegends,
  setSortingInfo,
  setVisibleColumns,
} = windLeasingAndProjectSlice.actions;

export const windLeasingAndProject = windLeasingAndProjectSlice.reducer;
