import { FC, useEffect, useMemo, useState } from "react";
import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  Box,
  DeleteOutlineIcon,
  getEsgianTheme,
  IconButton,
  Table,
  Typography,
} from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  getList,
  getSelectedItem,
  getTab,
} from "../../store/selector/powerOutput";
import { setSelectedItem } from "../../store/slice/powerOutput";
import { GenericType, THEME, ThemeModeEnum } from "../../types";
import { StyledAutocomplete } from "../StyledAutocomplete";

type Prop = {
  sx?: GenericType;
  selectedTab?: string;
  tableOverviewData: GenericType[];
};

export const PowerOutputCompareDeveloper: FC<Prop> = ({
  sx,
  selectedTab,
  tableOverviewData,
}) => {
  const dispatch = useDispatch();

  const tab = useSelector(getTab);
  const themeMode = useSelector(getThemeMode);
  const itemsObject = useSelector(getList);
  const theme = getEsgianTheme(themeMode, THEME);
  const selectedItem = useSelector(getSelectedItem);
  const [tableData, setTableData] = useState<GenericType[]>([{ name: null }]);
  const [expanded, setExpanded] = useState(false);

  const propertiesToCheck = [
    "dailyCapacity",
    "dailyOutput",
    "monthlyCapacity",
    "monthlyOutput",
    "weeklyCapacity",
    "weeklyOutput",
    "yearlyCapacity",
    "yearlyOutput",
    "ytdCapacity",
    "ytdOutput",
  ];

  const namesWithZeroValues: string[] = tableOverviewData
    ?.filter((item) => propertiesToCheck.every((prop) => item[prop] === 0))
    ?.map((item) => item.name);

  const handleRemoveProject = (index: number) => {
    dispatch(setSelectedItem(selectedItem[index]));
  };

  const tableRows = [...selectedItem];

  const list = useMemo(() => {
    return itemsObject?.[tab] || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (expanded && tableOverviewData?.length > 0) {
      tableRows.splice(1, 0, ...tableOverviewData.map((item) => item.id));
    }
    if (tableRows.length < 7 && list.length) {
      const entities: GenericType[] = tableRows
        .map((item: number) => {
          return (
            list.find((i: GenericType) => i.id == Number(item)) ||
            tableOverviewData?.find((i: GenericType) => i.id == Number(item))
          );
        })
        .filter((entity): entity is GenericType => entity !== undefined);
      if (tableRows.length < 6) {
        entities.push({ name: null });
      }

      setTableData(entities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, list, expanded, tableOverviewData]);

  //have a separate array with all the WIND FARM names that should be disabled

  const COLUMNS = useMemo(() => {
    return [
      {
        label: "Name",
        name: "name",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({
            style: {
              minWidth: 250,
              color:
                themeMode === ThemeModeEnum.Dark
                  ? theme.palette.common.white
                  : theme.palette.common.black,
            },
          }),
          customBodyRender: (
            value: string,
            tableMeta: { rowIndex: number | undefined },
            updateValue: (arg0: string) => void,
          ) => {
            if (!value) {
              const availableList = list?.filter(
                (project) => !selectedItem?.includes(project.id),
              );

              return (
                <StyledAutocomplete
                  disableClearable={true}
                  disableCloseOnSelect={false}
                  options={availableList || []}
                  setSelectedItem={(v: GenericType) => {
                    if (selectedItem.length < 6) {
                      dispatch(setSelectedItem(v.id));
                      updateValue(v.name);
                    }
                  }}
                  labelKey="name"
                  multipleSelection={false}
                  size="medium"
                  placeholder="Add Compare Item"
                  maxHeight="30vh"
                />
              );
            }
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="string"
                  sx={{
                    opacity: !namesWithZeroValues?.includes(value) ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (tableMeta.rowIndex === 0) {
                      setExpanded(!expanded);
                    }
                  }}
                >
                  {value}
                </Typography>
                {expanded && tableMeta.rowIndex === 0 ? (
                  <ArrowDropUpIcon
                    sx={{
                      color:
                        themeMode === ThemeModeEnum.Dark
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                      fontSize: "20px",
                    }}
                  />
                ) : (
                  tableMeta.rowIndex === 0 && (
                    <ArrowDropDownIcon
                      sx={{
                        color:
                          themeMode === ThemeModeEnum.Dark
                            ? theme.palette.common.white
                            : theme.palette.common.black,
                        fontSize: "20px",
                      }}
                    />
                  )
                )}
                {tableMeta.rowIndex !== 0 &&
                !tableOverviewData.find((item) => item.name === value) ? (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveProject(tableMeta.rowIndex || 0)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                ) : null}
              </Box>
            );
          },
        },
      },
      {
        label: "1 day",
        name: "dailyCapacity",
        options: {
          display: selectedTab === "capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 day",
        name: "dailyOutput",
        options: { display: selectedTab === "output" },
      },
      {
        label: "1 week",
        name: "weeklyCapacity",
        options: {
          display: selectedTab === "capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 week",
        name: "weeklyOutput",
        options: { display: selectedTab === "output" },
      },
      {
        label: "1 month",
        name: "monthlyCapacity",
        options: {
          display: selectedTab === "capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 month",
        name: "monthlyOutput",
        options: { display: selectedTab === "output" },
      },
      {
        label: "YTD",
        name: "ytdCapacity",
        options: {
          display: selectedTab === "capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "YTD",
        name: "ytdOutput",
        options: { display: selectedTab === "output" },
      },
      {
        label: "1-year",
        name: "yearlyCapacity",
        options: {
          display: selectedTab === "capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 year",
        name: "yearlyOutput",
        options: { display: selectedTab === "output" },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, tableRows, selectedTab, expanded]);

  let title = "";
  if (selectedTab === "capacity") {
    title = "Capacity factor";
  }
  if (selectedTab === "ppa") {
    title = "PPA";
  }
  if (selectedTab === "output") {
    title = "Power generation";
  }

  return (
    <Box
      sx={{
        background:
          themeMode === ThemeModeEnum.Dark ? "#171717" : "rgb(217, 217, 217)",
        p: 2,
        height: "100%",
        borderRadius: 4,
        // color: themeMode === ThemeModeEnum.Dark ? "#ffffff" : "#000000",
        ...sx,
      }}
    >
      <Typography variant="h6">{title} overview</Typography>
      <Box sx={{ overflowX: "auto" }}>
        <Table
          app="wind"
          columnDefs={[]}
          columns={COLUMNS}
          data={tableData}
          elevation={0}
          mode={themeMode}
          options={{
            download: false,
            search: false,
            downloadOptions: {
              filename: `turbine_models_data.csv`,
            },
            expandableRows: false,
            filter: false,
            hideToolbarMargin: false,
            pagination: false,
            responsive: "standard",
            selectableRows: "none",
            showResponsive: false,
            toolbar: true,
            print: false,
            viewColumns: false,
          }}
        />
      </Box>
    </Box>
  );
};
