import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  getEsgianTheme,
  Stack,
  Typography,
} from "@esgian/esgianui";
import moment from "moment/moment";

import { api } from "../../api/api";
import { Loading } from "../../components/Loading";
import { PowerOutputCompareDeveloper } from "../../components/PowerOutputCompareDeveloper";
import { PowerOutputCompareProject } from "../../components/PowerOutputCompareProject";
import { PowerOutputHeader } from "../../components/PowerOutputHeader";
import { PowerOutputHistoricalCapacityAreaChart } from "../../components/PowerOutputHistoricalCapacityAreaChart";
import { PowerOutputKeyBoxDeveloper } from "../../components/PowerOutputKeyBoxDeveloper";
import { PowerOutputKeyBoxProject } from "../../components/PowerOutputKeyBoxProject";
import { getErrorMessage } from "../../helper/errorHandling";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCountries,
  getIsLookupLoading,
  getThemeMode,
} from "../../store/selector/common";
import {
  getSelectedItem,
  getSelectedTimePeriod,
  getTab,
} from "../../store/selector/powerOutput";
import { fetchList, setTab } from "../../store/slice/powerOutput";
import {
  Country,
  GenericType,
  MESSAGE,
  PowerOutputEnum,
  THEME,
  ThemeModeEnum,
} from "../../types";

export const PowerOutputProfile = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const isLookupLoading = useSelector(getIsLookupLoading);
  const selectedRadio = useSelector(getTab);
  const selectedItem = useSelector(getSelectedItem);
  const timePeriod = useSelector(getSelectedTimePeriod);
  const countryList = useSelector(getCountries);

  const theme = getEsgianTheme(themeMode, THEME);
  const [selectedTab, setSelectedTab] = useState("output");
  const [mainTableData, setMainTableData] = useState<GenericType | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchProjectData = async (
    projectId: number,
    optionSelected: string,
  ) => {
    setLoading(true);
    try {
      let response = undefined;
      if (optionSelected === "wind-farm") {
        response = await api.project.getProjectListInfo({
          windProjectIds: [projectId],
          powerGenerationFilter: true,
        });
      } else if (optionSelected === "developer") {
        response = await api.project.getProjectListInfo({
          companyIds: [projectId],
          powerGenerationFilter: true,
        });
      }
      const { data: resp } = response;

      const { data: respTurbine } = await api.turbine.getTurbines({});
      const projTurbines: GenericType[] = [];
      let result: {
        project: string;
        developers: string;
        installedCapacity: number;
        numberOfTurbines: number;
        operationDate: string;
        turbineModel: string;
        country: string;
        turbineSupplier: string;
        turbinePower: string;
        powerGenStats: object;
        turbineChartData: {
          name: string;
          valueTurbine: number;
          valueMW: number;
        }[];
        tableOverviewData: {
          name: string;
          dailyCapacity: number;
          dailyOutput: number;
          installedCapacity: number;
          monthlyCapacity: number;
          monthlyOutput: number;
          weeklyCapacity: number;
          weeklyOutput: number;
          yearlyCapacity: number;
          yearlyOutput: number;
          ytdCapacity: number;
          ytdOutput: number;
          id: number;
        }[];
      } = {
        project: "",
        developers: "",
        installedCapacity: 0,
        numberOfTurbines: 0,
        operationDate: "",
        turbineModel: "",
        country: "",
        turbineSupplier: "",
        turbinePower: "",
        powerGenStats: {},
        turbineChartData: [],
        tableOverviewData: [],
      };

      if (optionSelected === "wind-farm") {
        const { windProject, developers } = resp[0];
        result = {
          project: windProject?.name,
          developers: developers
            ?.map((dev: GenericType) => `${dev.companyShortName}`)
            .join(", "),
          installedCapacity: windProject?.capacityMw,
          numberOfTurbines: windProject?.numTurbines,
          operationDate: windProject?.operationDate
            ? moment(windProject?.operationDate).format("YYYY-MM-DD")
            : "",
          turbineModel: windProject?.turbineModels
            ?.map((turbine: GenericType) => `${turbine.name}`)
            .join(", "),
          country:
            countryList?.find(
              (item: Country) => item.id === windProject.countryId,
            )?.name ?? "",
          turbineSupplier: "",
          turbinePower: "",
          powerGenStats: windProject.powerGenerationStats,
          turbineChartData: [],
          tableOverviewData: [],
        };
      } else if (optionSelected === "developer") {
        resp.forEach((item: GenericType) => {
          const { windProject, developers, contracts } = item;

          const ownershipPctg = developers.find(
            (company: GenericType) => company.id === projectId,
          )?.ownershipPercentage;
          let tempUnknownPctg = 0;
          let tempKnownPctg = 0;
          if (ownershipPctg > 0) {
            result.installedCapacity += Math.round(
              windProject?.capacityMw * (ownershipPctg / 100),
            );

            result.numberOfTurbines += Math.round(
              windProject?.numTurbines * (ownershipPctg / 100),
            );
          } else {
            //if the ownership percentage is 0, it's not defined, so we need to split equally the ownership percentage between all the companies
            tempUnknownPctg = developers.filter(
              (company: GenericType) => company.ownershipPercentage === 0,
            )?.length;
            developers
              .filter((company: GenericType) => company.ownershipPercentage > 0)
              .forEach((dev: GenericType) => {
                tempKnownPctg += dev.ownershipPercentage;
              });
            result.installedCapacity += Math.round(
              (windProject?.capacityMw *
                ((100 - tempKnownPctg) / tempUnknownPctg)) /
                100,
            );

            result.numberOfTurbines += Math.round(
              (windProject?.numTurbines *
                ((100 - tempKnownPctg) / tempUnknownPctg)) /
                100,
            );
          }
          result.tableOverviewData.push({
            name: windProject.name,
            dailyCapacity: windProject.powerGenerationStats?.dailyCapacity,
            dailyOutput: Number(
              (windProject.powerGenerationStats?.dailyOutput / 1000).toFixed(2),
            ),
            installedCapacity:
              windProject.powerGenerationStats?.installedCapacity,
            monthlyCapacity: windProject.powerGenerationStats?.monthlyCapacity,
            monthlyOutput: Number(
              (windProject.powerGenerationStats?.monthlyOutput / 1000).toFixed(
                2,
              ),
            ),
            weeklyCapacity: windProject.powerGenerationStats?.weeklyCapacity,
            weeklyOutput: Number(
              (windProject.powerGenerationStats?.weeklyOutput / 1000).toFixed(
                2,
              ),
            ),
            yearlyCapacity: windProject.powerGenerationStats?.yearlyCapacity,
            yearlyOutput: Number(
              (windProject.powerGenerationStats?.yearlyOutput / 1000).toFixed(
                2,
              ),
            ),
            ytdCapacity: windProject.powerGenerationStats?.ytdCapacity,
            ytdOutput: Number(
              (windProject.powerGenerationStats?.ytdOutput / 1000).toFixed(2),
            ),
            id: windProject.id,
          });

          // result.turbinePower = Number(result.turbinePower) and after/number of elements in the array ;
          if (!result.turbineModel) {
            result.turbineModel = result.turbineModel.concat(
              windProject?.turbineModels
                ?.map((turbine: GenericType) => `${turbine.name}`)
                .join(", "),
            );
          } else {
            result.turbineModel = result.turbineModel.concat(
              ", ",
              windProject?.turbineModels
                ?.map((turbine: GenericType) => `${turbine.name}`)
                .join(", "),
            );
          }
          //for the chart data I need to define how many turbines there are, which model, and how many of each model
          if (windProject?.turbineModels.length > 1) {
            windProject?.turbineModels?.forEach((turbine: GenericType) => {
              projTurbines.push({
                name: turbine.name,
                valueTurbine: 0,
                valueMW: 0,
              });
              const turbineContracts =
                contracts.equipmentContracts.turbineContracts.filter(
                  (contractTurbine: GenericType) =>
                    contractTurbine?.contract?.contract?.contract
                      ?.equipmentTypes?.[0]?.id === 11 &&
                    contractTurbine?.contract?.contract?.contract
                      ?.contractWorkType?.id === 1 &&
                    contractTurbine?.contract?.turbineModel?.name ===
                      turbine.name,
                );
              turbineContracts.forEach((contract: GenericType) => {
                const turbineFound = projTurbines.find(
                  (turbineObj) => turbineObj.name === turbine.name,
                );
                if (turbineFound) {
                  turbineFound.valueTurbine += contract?.contract?.numOrdered;
                }
              });
            });
          } else {
            const foundTurbine = projTurbines.find(
              (turbine) =>
                turbine.name === windProject?.turbineModels?.[0].name,
            );
            if (!foundTurbine) {
              projTurbines.push({
                name: windProject?.turbineModels?.[0].name,
                valueTurbine: result.numberOfTurbines,
                valueMW: result.installedCapacity,
              });
            } else {
              foundTurbine.valueTurbine += result.numberOfTurbines;
              foundTurbine.valueMW += result.installedCapacity;
            }
          }
        });
      }

      result?.turbineModel?.split(", ").forEach((model: string) => {
        const turbineSuppliers = respTurbine.find(
          (item: GenericType) => item.turbineModel.name === model,
        )?.turbineModel.suppliers;

        const projTurbine = projTurbines?.find(
          (turbine) => turbine.name === model,
        );
        if (projTurbine && projTurbine.valueMW === 0) {
          projTurbine.valueMW += respTurbine.find(
            (item: GenericType) => item.turbineModel.name === model,
          ).turbineModel.turbineNominalPower;
        }

        turbineSuppliers?.forEach((item: GenericType) => {
          if (!result.turbineSupplier) {
            result.turbineSupplier = result.turbineSupplier.concat(
              item.companyName,
            );
          } else {
            result.turbineSupplier = result.turbineSupplier.concat(
              ", ",
              item.companyName,
            );
          }
          const existingSupplier = result.turbineChartData.find(
            (supplier) => supplier.name === item.companyName,
          );
          const foundTurbine = projTurbines.find(
            (turbine) => turbine.name === model,
          );
          if (!existingSupplier) {
            result.turbineChartData.push({
              name: item.companyName,
              valueTurbine: foundTurbine?.valueTurbine,
              valueMW: foundTurbine?.valueMW,
            });
          } else {
            existingSupplier.valueTurbine += foundTurbine?.valueTurbine;
            existingSupplier.valueMW += foundTurbine?.valueMW;
          }
        });

        const turbinePowers = respTurbine.find(
          (item: GenericType) => item.turbineModel.name === model,
        )?.turbineModel.turbineNominalPower;
        if (!result.turbinePower) {
          result.turbinePower = result.turbinePower.concat(turbinePowers);
        } else {
          result.turbinePower = result.turbinePower.concat(", ", turbinePowers);
        }
      });
      setMainTableData(result);
    } catch (err) {
      console.log(">>", err);
      toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
    } finally {
      setLoading(false);
    }
  };

  console.log(loading);

  useEffect(() => {
    if (!isLookupLoading) {
      dispatch(fetchList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLookupLoading]);

  useEffect(() => {
    if (selectedItem.length) {
      fetchProjectData(selectedItem?.[0], selectedRadio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, selectedRadio]);

  const title = useMemo(() => {
    return selectedRadio
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }, [selectedRadio]);

  useEffect(() => {
    return () => {
      dispatch(setTab(PowerOutputEnum.WindFarm));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ px: 2, py: 2 }}>
      {!loading && !isLookupLoading ? (
        <Box sx={{ overflow: "auto", height: "calc(100vh - 60px)" }}>
          <Stack direction="column" spacing={2} sx={{ position: "stciky" }}>
            <PowerOutputHeader />
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                disableElevation={true}
                onClick={() => setSelectedTab("output")}
                color={selectedTab === "output" ? "primary" : "secondary"}
                variant="contained"
              >
                Power generation
              </Button>
              <Button
                size="small"
                disableElevation={true}
                onClick={() => setSelectedTab("capacity")}
                color={selectedTab === "capacity" ? "primary" : "secondary"}
                variant="contained"
              >
                Capacity factor
              </Button>
              <Button
                size="small"
                disableElevation={true}
                onClick={() => setSelectedTab("ppa")}
                color={selectedTab === "ppa" ? "primary" : "secondary"}
                variant="contained"
              >
                PPA
              </Button>
            </Stack>
          </Stack>

          {selectedItem?.length && timePeriod ? (
            <Stack direction="column" spacing={2}>
              <Box>
                {selectedRadio === "wind-farm" && (
                  <PowerOutputKeyBoxProject projectData={mainTableData} />
                )}
                {selectedRadio === "developer" && (
                  <PowerOutputKeyBoxDeveloper projectData={mainTableData} />
                )}
              </Box>
              {selectedTab !== "ppa" && (
                <Box>
                  {selectedRadio === "wind-farm" && (
                    <PowerOutputCompareProject selectedTab={selectedTab} />
                  )}
                  {selectedRadio === "developer" && (
                    <PowerOutputCompareDeveloper
                      selectedTab={selectedTab}
                      tableOverviewData={mainTableData?.tableOverviewData}
                    />
                  )}
                </Box>
              )}

              <Box
                sx={{
                  background:
                    themeMode === ThemeModeEnum.Dark
                      ? "#171717"
                      : "rgb(217, 217, 217)",
                  p: 2,
                  height: "100%",
                  borderRadius: 4,
                  color:
                    themeMode === ThemeModeEnum.Dark
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                }}
              >
                <PowerOutputHistoricalCapacityAreaChart
                  selectedTab={selectedTab}
                  sx={{ mt: 2 }}
                />
              </Box>
            </Stack>
          ) : (
            <Box
              sx={{
                background:
                  themeMode === ThemeModeEnum.Dark
                    ? "#171717"
                    : "rgb(217, 217, 217)",
                p: 2,
                height: 200,
                borderRadius: 4,
                my: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color:
                  themeMode === ThemeModeEnum.Dark
                    ? theme.palette.common.white
                    : theme.palette.common.black,
              }}
            >
              <Typography variant="h6" sx={{ py: 2 }}>
                Please Select {title} and Time Period
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
