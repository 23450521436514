import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Table,
  Typography,
} from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import {
  getList,
  getSelectedFilter,
  getSortingInfo,
} from "../../store/selector/powerOutput";
import { setRowsPerPage } from "../../store/slice/common";
import { setSelectedItem, setSortingInfo } from "../../store/slice/powerOutput";
import {
  GenericType,
  IdNamePair,
  PowerOutputEnum,
  ROUTES_CONFIG,
} from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

type Prop = {
  tab: PowerOutputEnum;
  selectedView: string;
  setSelectedView: (selectedValue: string) => void;
};

export const PowerOutputProjectList: FC<Prop> = ({
  tab,
  selectedView,
  setSelectedView,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortingInfo = useSelector(getSortingInfo);
  const itemsObject = useSelector(getList);
  const rowsPerPage = useSelector(getRowsPerPage);
  const themeMode = useSelector(getThemeMode);
  const selectedFilter = useSelector(getSelectedFilter);
  const searchText = useSelector(getSearchText);

  const title = useMemo(() => {
    if (!tab.endsWith("y")) {
      return tab.toLocaleLowerCase().replace("-", " ") + "s";
    }
    return tab.toLocaleLowerCase().replace("-", " ").slice(0, -1) + "ies";
  }, [tab]);

  const data = useMemo(() => {
    let list = itemsObject?.[tab];
    if (!list?.length) return [];
    list = filterDataBasedOnDynamicKeys(list, selectedFilter) as GenericType[];

    if (searchText?.length && list?.length) {
      list = list?.filter((item: GenericType) => isMatch(item, [searchText]));
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, itemsObject, selectedFilter, tab]);

  const COLUMNS = useMemo(
    () => [
      {
        label: "Name",
        name: "name",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Current installed capacity (MW)",
        name: "installedCapacity",
      },
      {
        label: "1 day",
        name: "dailyCapacity",
        options: {
          display: selectedView === "Capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 day",
        name: "dailyOutput",
        options: { display: selectedView === "Output" },
      },
      {
        label: "1 week",
        name: "weeklyCapacity",
        options: {
          display: selectedView === "Capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 week",
        name: "weeklyOutput",
        options: { display: selectedView === "Output" },
      },
      {
        label: "1 month",
        name: "monthlyCapacity",
        options: {
          display: selectedView === "Capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 month",
        name: "monthlyOutput",
        options: { display: selectedView === "Output" },
      },
      {
        label: "YTD",
        name: "ytdCapacity",
        options: {
          display: selectedView === "Capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "YTD",
        name: "ytdOutput",
        options: { display: selectedView === "Output" },
      },
      {
        label: "1 year",
        name: "yearlyCapacity",
        options: {
          display: selectedView === "Capacity",
          customBodyRender: (value: string) => {
            if (value) {
              return value + " %";
            }
          },
        },
      },
      {
        label: "1 year",
        name: "yearlyOutput",
        options: { display: selectedView === "Output" },
      },
    ],
    [selectedView],
  );

  return (
    <Box sx={{ px: 2 }}>
      {data ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} ${title}`}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={5}
              alignItems="baseline"
            >
              <Stack spacing={1} direction="row" alignItems="baseline">
                <Typography variant="body2">View by</Typography>
                <FormControl disabled={false} size="small">
                  <Select
                    variant="outlined"
                    inputProps={{ sx: { pl: 1, pr: 1, pt: 0.5, pb: 0.5 } }}
                    value={selectedView}
                    onChange={({ target }: GenericType) =>
                      setSelectedView(target.value)
                    }
                    autoWidth="true"
                  >
                    <MenuItem value="Output">Power generation</MenuItem>
                    <MenuItem value="Capacity">Capacity factor</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <FilterBtn count={calculateFilterCount(selectedFilter)} />
            </Stack>
          </Stack>
          <Table
            app="wind"
            title={`${selectedView === "Output" ? "Power output" : "Capacity factor"} ${title} ${selectedView === "Output" ? "- GWh" : ""}`}
            columnDefs={[]}
            columns={COLUMNS}
            data={data || []}
            elevation={0}
            mode={themeMode}
            options={{
              download: true,
              search: false,
              downloadOptions: {
                filename: `power_output_data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "dropdown",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "vertical",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item = data?.[dataIndex];
                dispatch(setSelectedItem(item.id));
                navigate(`${ROUTES_CONFIG.PowerOutputProfile.url}`);
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 320px)",
              toolbar: true,
              print: false,
              viewColumns: false,
              sortThirdClickReset: true,
              onViewColumnsChange: () => {},
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
