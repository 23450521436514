import { FC } from "react";
import {
  Box,
  getEsgianTheme,
  ProgressBar,
  Stack,
  Typography,
} from "@esgian/esgianui";

import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

type Prop = {
  sx?: GenericType;
  projectData?: GenericType | null;
};

export const PowerOutputKeyBoxProject: FC<Prop> = ({
  sx,
  projectData = null,
}) => {
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);

  return (
    <Box
      sx={{
        background:
          themeMode === ThemeModeEnum.Dark ? "#171717" : "rgb(217, 217, 217)",
        p: 2,
        height: "22rem",
        borderRadius: 4,
        color:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
        ...sx,
      }}
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="h6">Key figures</Typography>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2">Power data coverage</Typography>
          <ProgressBar value="10" />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxHeight: "16vh",
            mt: 10,
            justifyContent: "space-around",
            textAlign: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Stack direction="column" sx={{ textAlign: "center" }}>
                <Typography variant="body2">Operation date</Typography>
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  {projectData?.operationDate}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Country</Typography>
              <Typography variant="h6">{projectData?.country}</Typography>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Installed capacity</Typography>
              <Typography
                variant="h6"
                sx={{
                  height: "8vh",
                }}
              >
                {projectData?.installedCapacity} MW
              </Typography>
            </Stack>
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Power generation</Typography>
              <Typography variant="h6"> MW</Typography>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              height: "16vh",
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Number of turbines</Typography>
              <Typography
                variant="h6"
                sx={{
                  height: "8vh",
                }}
              >
                {projectData?.numberOfTurbines}
              </Typography>
            </Stack>
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Capacity factor</Typography>
              <Typography variant="h6">TBI</Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            spacing={2}
            sx={{
              height: "16vh",
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ textAlign: "center", height: "16vh" }}
            >
              <Typography variant="body2">Turbine nominal power</Typography>
              <Box
                sx={{
                  overflowY: "auto",
                  height: "8vh",
                  maxHeight: "8vh",
                }}
              >
                {projectData?.turbinePower?.split(", ").map((power: string) => {
                  return (
                    <Typography key={power} variant="h6">
                      {power} MW
                    </Typography>
                  );
                })}
              </Box>
            </Stack>
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Max. capacity factor</Typography>
              <Typography variant="h6">TBI</Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Turbine supplier</Typography>
              <Box
                sx={{
                  overflowY: "auto",
                  height: "8vh",
                  maxHeight: "8vh",
                }}
              >
                {projectData?.turbineSupplier
                  ?.split(", ")
                  .map((supplier: string) => {
                    return (
                      <Typography
                        alignSelf="center"
                        sx={{
                          textOverflow: "ellipsis",
                          maxWidth: "7vw",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        title={supplier}
                        key={supplier}
                        variant="h6"
                      >
                        {supplier}
                      </Typography>
                    );
                  })}
              </Box>
            </Stack>
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Min. capacity factor</Typography>
              <Typography variant="h6">TBI</Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Stack direction="row" alignSelf="center" spacing={0.5}>
                <Typography variant="body2">Turbine model</Typography>
              </Stack>
              <Box
                sx={{
                  overflowY: "auto",
                  height: "8vh",
                  maxHeight: "8vh",
                }}
              >
                {projectData?.turbineModel?.split(", ").map((model: string) => {
                  return (
                    <Typography key={model} variant="h6">
                      {model}
                    </Typography>
                  );
                })}
              </Box>
            </Stack>
            <Stack direction="column" sx={{ textAlign: "center" }}>
              <Typography variant="body2">Developer(s)</Typography>
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "8vh",
                }}
              >
                {projectData?.developers?.split(", ").map((dev: string) => {
                  return (
                    <Typography
                      key={dev}
                      variant="h6"
                      alignSelf="center"
                      sx={{
                        textOverflow: "ellipsis",
                        maxWidth: "7vw",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={dev}
                    >
                      {dev}
                    </Typography>
                  );
                })}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
